<template>
  <div>
    <MemberList />
  </div>
</template>

<script>
import PageHeaderLayout from '@/layouts/PageHeaderLayout'
import MemberList from '@/pages/team/member/MemberList'

export default {
  name: 'TeamMemberList',
  components: { MemberList, PageHeaderLayout },
  data() {
    return {}
  }

}
</script>

<style scoped>

</style>