<template>
  <div>
    <page-header-layout>
      <div class='main-page-content'>
        <el-card>
          <div class='table-search'>
            <el-form :model='searchCondition' inline size='mini'>
              <el-form-item label='岗位：'>
                <el-input placeholder='岗位名称' v-model='searchCondition.position' clearable></el-input>
              </el-form-item>
              <el-form-item label='姓名：'>
                <el-input placeholder='用户姓名' v-model='searchCondition.name' clearable></el-input>
              </el-form-item>
              <el-form-item label='在职：'>
                <el-select placeholder='在职情况' v-model='searchCondition.is_leave' style='width: 120px' clearable>
                  <el-option label='在职' value='N'></el-option>
                  <el-option label='离职' value='Y'></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label='创建时间：'>
                <el-date-picker
                  v-model='searchCondition.date_range'
                  type='daterange'
                  align='right'
                  unlink-panels
                  range-separator='至'
                  start-placeholder='开始日期'
                  end-placeholder='结束日期'
                  value-format='yyyy-MM-dd'
                  :picker-options='pickerOptions'>
                </el-date-picker>
              </el-form-item>
              <el-form-item>
                <el-button type='primary' icon='el-icon-search' @click='handleQuery'>搜索</el-button>
              </el-form-item>
            </el-form>
          </div>
          <div class='default-table'>
            <!--    渲染表格-->
            <m-table :showIndex='true' :offset='offset' max-height='800' :tableData='dataList' :columns='columns'
                     :loading='loadingStatus' @sort-change='changeTableSort' border>
<!--              <el-table-column type='index' label='序号' width='80' align='center'></el-table-column>-->
<!--              <el-table-column label='部门' prop='dept_name' align='center' sortable></el-table-column>-->
<!--              <el-table-column label='岗位' prop='position' align='center' sortable></el-table-column>-->
<!--              <el-table-column label='姓名' prop='name' align='center' sortable>-->
<!--              </el-table-column>-->
              <el-table-column label='入职时间' prop='hired_date' align='center' sortable>
                <template slot-scope='{row}'>
                  {{ row.hired_date === '1970-01-01' ? '' : row.hired_date }}
                </template>
              </el-table-column>
              <el-table-column label='调入时间' prop='in_at' align='center' sortable>
              </el-table-column>
              <el-table-column label='离职时间' prop='leave_date' align='center' sortable></el-table-column>
              <el-table-column label='调动时间' prop='out_at' align='center' sortable></el-table-column>
              <el-table-column label='调动部门' prop='out_dept_name' align='center' sortable></el-table-column>
              <el-table-column label='操作' width='100' align='center'>
                <template slot-scope='{row}'>
                  <el-button size='mini' type='text' icon='el-icon-view' @click='handleView(row)'>详情</el-button>
                </template>
              </el-table-column>
            </m-table>
            <!--    渲染分页-->
            <m-pagination :total.sync='total' :limit.sync='pagingData.page_size' :page.sync='pagingData.current_page'
                          @pagination='getList' />
          </div>
        </el-card>
      </div>
    </page-header-layout>
    <!-- 操作日志详细 -->
    <el-dialog title='详情' :visible.sync='open' width='700px' append-to-body>
      <el-form ref='form' :model='form' size='mini'>
        <el-row>
          <el-col :span='24'>
            <el-form-item label=''>
              <el-input :autosize='{ minRows: 15, maxRows: 40}' type='textarea'
                        :value='jsonData'></el-input>
            </el-form-item>

          </el-col>
        </el-row>
      </el-form>
      <div slot='footer' class='dialog-footer'>
        <el-button @click='open = false'>关 闭</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import PageHeaderLayout from '@/layouts/PageHeaderLayout'
export default {
  name: 'MemberList',
  components: { PageHeaderLayout },
  data() {
    return {
      // 是否显示弹出层
      open: false,
      form: {},
      loadingStatus: false,
      orderSort: { id: 'desc' },
      searchCondition: { dept_name: null, dpt_id: null, name: null, is_leave: null, date_range: [] },
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick(picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '最近一个月',
          onClick(picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '最近三个月',
          onClick(picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
            picker.$emit('pick', [start, end])
          }
        }]
      },
      columns: [
        {
          title: '部门',
          value: 'dept_name',
          field: 'dept_name',
          width: 120,
          sortable:'custom'
        }, {
          title: '岗位',
          value: 'position',
          field: 'position',
          width: 80,
          sortable:'custom'
        },
        {
          title: '姓名',
          value: 'name',
          field: 'name',
          width: 80,
          sortable:'custom'
        },
      ],
      dataList: [],
      total: 0,
      offset: 0,
      pagingData: {
        page_size: 15,
        current_page: 1
      },
      leaveStatusMap: { 'Y': '离职', 'N': '在职' },
      currRow: {},
      dialogVisible: false,
      real_count: 0,
      num_job: 0,
      num_leave: 0,
    }
  },
  computed: {
    jsonData() {
      if (this.form)
        return JSON.stringify(this.form, null, 4)
      else
        return JSON.stringify({})
      // return this.formData.notify_msg || {}
    }
  },
  methods: {
    changeTableSort({ prop, order }) {
      let orderSort = {}
      if (order) {
        orderSort[prop] = order === 'descending' ? 'desc' : 'asc'
      }
      this.orderSort = orderSort
      this.getList()

    },
    handleQuery() {
      this.pagingData.current_page = 1
      this.getList()

    },
    // 处理搜索条件
    handleSearchCondition() {
      let condition = {}
      if (this.searchCondition.name) {
        condition.name = this.searchCondition.name
      }
      if (this.searchCondition.dpt_id) {
        condition.dpt_id = this.searchCondition.dpt_id
      }
      if (this.searchCondition.is_leave) {
        condition.is_leave = this.searchCondition.is_leave
      }
      if (this.searchCondition.date_range) {
        condition.date_range = this.searchCondition.date_range
      }
      return condition
    },
    async getList() {
      this.loadingStatus = true
      let searchCondition = this.handleSearchCondition()
      // 合并
      Object.assign(searchCondition, this.pagingData, { order: this.orderSort })
      let { list, pages } = await this.$api.getTeamMemberList(searchCondition)
      this.$nextTick(() => {
        this.dataList = list
        this.total = pages.total
        this.offset = pages.offset
        this.pagingData.current_page = pages.current_page
        this.pagingData.page_size = pages.page_size
        this.loadingStatus = false
      })

    },
    /** 详细按钮操作 */
    handleView(row) {
      this.open = true
      this.form = row
    },
  },
  mounted() {
    this.getList()
  }
}
</script>

<style scoped>

</style>